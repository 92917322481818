{
  "es": {
    "js": {
      "checkout": {
        "selected_plan": "Plan seleccionado",
        "selected_period": "Período seleccionado",
        "price": "Precio",
        "monthly": "Mensual",
        "yearly": "Anual",
        "per_month": "/mes",
        "per_year": "/año"
      },
      "copy_link_modal": {
        "confirm_button_aria_label": "Copiar al portapapeles",
        "confirm_button_text": "<i class=\"fa fa-clipboard\"></i> Copiar",
        "connected": "Conectado al modal de copiar enlace",
        "copied_message": "El enlace ha sido copiado al portapapeles.",
        "copied_title": "¡Copiado!",
        "error_message": "No se pudo copiar el texto: ",
        "focus_confirm": "Enfocar confirmar",
        "html": "<b>%{link}</b>",
        "show_close_button": "Mostrar botón de cerrar",
        "title": "<strong>Copiar enlace</strong>"
      },
      "image_upload": {
        "error_invalid_file_type": "Tipo de archivo no válido",
        "error_file_must_be_jpg_or_png": "El archivo debe ser una imagen JPG o PNG.",
        "error_file_too_large": "Archivo demasiado grande",
        "error_max_size_10mb": "El archivo debe ser menor de 10MB."
      }
    }
  }
}

