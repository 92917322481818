import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "select", "option", "content"]

  select(event) {
    event.preventDefault()

    this.tabTargets.forEach(tab => tab.classList.remove('text-primary'))
    this.contentTargets.forEach(content => content.classList.add('hidden'))

    event.currentTarget.classList.add('text-primary')
    const tabId = event.currentTarget.dataset.tabId
    const contentToShow = this.contentTargets.find(content => content.id === tabId)

    if (contentToShow) {
      contentToShow.classList.remove('hidden')
      this.updateSelect(tabId)
    }
  }

  changeSelect(event) {
    const selectedValue = event.target.value

    this.tabTargets.forEach(tab => tab.classList.remove('text-primary'))
    this.contentTargets.forEach(content => content.classList.add('hidden'))

    const matchingTab = this.tabTargets.find(tab => tab.dataset.tabId === selectedValue)
    const contentToShow = this.contentTargets.find(content => content.id === selectedValue)

    if (matchingTab) {
      matchingTab.classList.add('text-primary')
    }
    if (contentToShow) {
      contentToShow.classList.remove('hidden')
    }
  }

  updateSelect(tabId) {
    this.selectTarget.value = tabId
    this.optionTargets.forEach(option => {
      option.selected = option.value === tabId
      if (option.value === tabId) {
        option.classList.add('text-primary');
      } else {
        option.classList.remove('text-primary');
      }
    })
  }
}
