import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "step1",
    "step2",
    "step3",
    "planSelector",
    "descriptionMini",
    "descriptionBasic",
    "descriptionStandard",
    "descriptionPro",
    "descriptionElite",
    "summaryText",
    "monthlyButton",
    "yearlyButton",
    "price",
    "monthlyTotalPrice",
    "hiddenPeriod"
  ];

  connect() {
    this.currentStep = 1;
    this.showStep(this.currentStep);
    this.showDescription("mini");
    this.selectedPlan = "mini";
    this.selectedPeriod = "monthly";
    this.prices = JSON.parse(this.planSelectorTarget.dataset.prices);
    this.updatePrice();
  }

  updatePlan(event) {
    this.selectedPlan = this.planSelectorTarget.value;
    this.showDescription(this.selectedPlan);
    this.updatePrice();
  }

  showDescription(plan) {
    this.hideAllDescriptions();
    switch (plan) {
      case "mini":
        this.descriptionMiniTarget.classList.remove("hidden");
        break;
      case "basic":
        this.descriptionBasicTarget.classList.remove("hidden");
        break;
      case "standard":
        this.descriptionStandardTarget.classList.remove("hidden");
        break;
      case "pro":
        this.descriptionProTarget.classList.remove("hidden");
        break;
      case "elite":
        this.descriptionEliteTarget.classList.remove("hidden");
        break;
    }
  }

  hideAllDescriptions() {
    this.descriptionMiniTarget.classList.add("hidden");
    this.descriptionBasicTarget.classList.add("hidden");
    this.descriptionStandardTarget.classList.add("hidden");
    this.descriptionProTarget.classList.add("hidden");
    this.descriptionEliteTarget.classList.add("hidden");
  }

  selectMonthly() {
    this.deselectButtons();
    this.monthlyButtonTarget.classList.add("btn-selected");
    this.selectedPeriod = "monthly";
    this.hiddenPeriodTarget.value = "monthly";
    this.monthlyTotalPriceTarget.classList.add("hidden");
    this.updatePrice();
  }

  selectYearly() {
    this.deselectButtons();
    this.yearlyButtonTarget.classList.add("btn-selected");
    this.selectedPeriod = "yearly";
    this.hiddenPeriodTarget.value = "yearly";
    this.updatePrice();
  }

  updatePrice() {
    const locale = document.body.getAttribute('data-locale');
    const price = this.prices[this.selectedPlan][this.selectedPeriod];
    const suffix = this.selectedPeriod === "monthly"
        ? `${I18n.t[locale]['js']['checkout']['per_month']}`
        : `${I18n.t[locale]['js']['checkout']['per_year']}`;

    this.priceTarget.innerHTML = `${price}€${suffix}`;

    if (this.selectedPeriod === "yearly") {
      const totalMonthlyPrice = (this.prices[this.selectedPlan]["monthly"] * 12).toFixed(2);
      this.monthlyTotalPriceTarget.innerHTML = `${totalMonthlyPrice} €`;
      this.monthlyTotalPriceTarget.classList.remove("hidden");
    } else {
      this.monthlyTotalPriceTarget.classList.add("hidden");
    }
  }


  nextStep() {
    if (this.currentStep < 3) {
      this.currentStep++;
      this.showStep(this.currentStep);
    }
    if (this.currentStep === 3) {
      this.updateSummary();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.showStep(this.currentStep);
    }
  }

  showStep(step) {
    this.step1Target.classList.toggle("hidden", step !== 1);
    this.step2Target.classList.toggle("hidden", step !== 2);
    this.step3Target.classList.toggle("hidden", step !== 3);
  }

  updateSummary() {
    const locale = document.body.getAttribute('data-locale');
    const selectedPlanText = this.planSelectorTarget.options[this.planSelectorTarget.selectedIndex].text;
    const periodText = this.selectedPeriod === "monthly"
        ? I18n.t[locale]['js']['checkout']['monthly']
        : I18n.t[locale]['js']['checkout']['yearly'];

    const price = this.prices[this.selectedPlan][this.selectedPeriod];

    const summary = `
    <div class="grid grid-cols-2 gap-x-4 gap-y-2 justify-center">
      <div class="text-right">${I18n.t[locale]['js']['checkout']['selected_plan']}:</div>
      <div class="text-left font-bold">${selectedPlanText}</div>
      
      <div class="text-right">${I18n.t[locale]['js']['checkout']['selected_period']}:</div>
      <div class="text-left font-bold">${periodText}</div>
      
      <div class="text-right">${I18n.t[locale]['js']['checkout']['price']}:</div>
      <div class="text-left font-bold">${price} EUR</div>
    </div>
  `;

    this.summaryTextTarget.innerHTML = summary;
  }

  deselectButtons() {
    this.monthlyButtonTarget.classList.remove("btn-selected");
    this.yearlyButtonTarget.classList.remove("btn-selected");
  }
}
