import "@hotwired/turbo-rails"
import "@rails/activestorage"
import "./controllers"
require("@rails/activestorage").start()

// Y el resto de tu código existente...
import './add_sweetalert2'
import './turbo_set_confirm_method'
import './add_vanilla_datetimerange_picker'
import './add_slimselect'
import './add_alpine'
import './add_i18n'
import 'chartkick/chart.js'
import "trix"
import "@rails/actiontext"
import './add_glightbox'
import './remove_turbo_progress_bar'
import './add_moment'
import './add_biggerpicture'
import './add_tagify'
