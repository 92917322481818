import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Controller {
  connect() {
    const locale = this.element.dataset.datetimepickerLocale || "en";

    const locales = {
      es: Spanish,
      fr: French,
      it: Italian,
      en: english
    };

    flatpickr(this.element, {
      enableTime: this.element.dataset.datetimepickerEnableTime || false,
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: this.element.dataset.datetimepickerDateFormat || 'm / d / Y',
      minDate: this.element.dataset.datetimepickerMinDate,
      defaultDate: this.element.value || new Date(),
      locale: locales[locale] || english
    });
  }
}
