import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.bp = BiggerPicture({
      target: document.body
    });

    this.imageLinks = this.element.querySelectorAll("#images > a");

    this.imageLinks.forEach(link => {
      const img = new Image();
      img.src = link.dataset.img;

      img.onload = () => {
        link.dataset.width = img.width;
        link.dataset.height = img.height;
      };

      link.addEventListener("click", (event) => this.openGallery(event));
    });

  }

  openGallery(event) {
    event.preventDefault();
    const link = event.currentTarget;

    this.bp.open({
      items: this.imageLinks,
      el: link,
    });
  }
}
