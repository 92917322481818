// Stimulus controller (refresh-city-state_controller.js)
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["country", "state", "city"]

  refreshStates() {
    const country = this.countryTarget.value;
    fetch(`/actors/states?country=${country}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(response => response.json())
        .then(data => {
          this.updateSelectOptions(this.stateTarget, data.states);
        });
  }

  refreshCities() {
    const country = this.countryTarget.value;
    const state = this.stateTarget.value;
    fetch(`/actors/cities?country=${country}&state=${state}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(response => response.json())
        .then(data => {
          this.updateSelectOptions(this.cityTarget, data.cities);
          this.cityTarget.disabled = false;
        });
  }

  updateSelectOptions(selectElement, options) {
    selectElement.innerHTML = '';
    options.forEach(option => {
      let opt = document.createElement('option');
      opt.value = option.value;
      opt.textContent = option.text;
      selectElement.appendChild(opt);
    });
    selectElement.disabled = false;

    const slimController = this.application.getControllerForElementAndIdentifier(selectElement, "slim");
    if (slimController && slimController.slimSelect) {
      slimController.slimSelect.setData(options.map(option => ({text: option.text, value: option.value})));
      slimController.slimSelect.enable();
    }
  }

}
