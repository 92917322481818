{
  "fr": {
    "js": {
      "checkout": {
        "selected_plan": "Plan sélectionné",
        "selected_period": "Période sélectionnée",
        "price": "Prix",
        "monthly": "Mensuel",
        "yearly": "Annuel",
        "per_month": "/mois",
        "per_year": "/an"
      },
      "copy_link_modal": {
        "confirm_button_aria_label": "Copier dans le presse-papiers",
        "confirm_button_text": "<i class=\"fa fa-clipboard\"></i> Copier",
        "connected": "Connecté à la fenêtre modale de copie de lien",
        "copied_message": "Le lien a été copié dans votre presse-papiers.",
        "copied_title": "Copié!",
        "error_message": "Impossible de copier le texte: ",
        "focus_confirm": "Concentrer confirmer",
        "html": "<b>%{link}</b>",
        "show_close_button": "Afficher le bouton fermer",
        "title": "<strong>Copier le lien</strong>"
      },
      "image_upload": {
        "error_invalid_file_type": "Type de fichier invalide",
        "error_file_must_be_jpg_or_png": "Le fichier doit être une image JPG ou PNG.",
        "error_file_too_large": "Fichier trop volumineux",
        "error_max_size_10mb": "Le fichier doit faire moins de 10 Mo."
      }
    }
  }
}
