import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-season-form"
export default class extends Controller {
  static targets = ['mainForm', 'mediaForm', 'availabilitiesForm', 'yesNoQuestionsForm', 'openQuestionsForm']

  connect() {
    this.completedForms = new Set();
    this.handleTurboStreamFinished = this.handleTurboStreamFinished.bind(this);
    document.addEventListener('turbo-stream-finished', this.handleTurboStreamFinished);
  }

  disconnect() {
    document.removeEventListener('turbo-stream-finished', this.handleTurboStreamFinished);
  }

  handleTurboStreamFinished(event) {
    if (event.detail && event.detail.type) {
      this.completedForms.add(event.detail.type);
      this.checkIfAllFormsSubmitted();
    }
  }

  clearCompletedForms() {
    this.completedForms.clear();
  }

  checkIfAllFormsSubmitted() {
    const allForms = ['media', 'availabilities', 'yes_no_questions', 'open_questions'];
    if (allForms.every(form => this.completedForms.has(form))) {
      this.mainFormTarget.submit();
    }
  }

  async submit_search_season(e) {
    e.preventDefault();

    const submitForm = (formTarget) => {
      return new Promise((resolve, reject) => {
        if (formTarget) {
          document.addEventListener('turbo-stream-finished', this.handleTurboStreamFinished, { once: true });

          formTarget.addEventListener('turbo:submit-end', (event) => {
            if (event.detail.success) {
              resolve();
            } else {
              reject();
            }
          }, { once: true });

          formTarget.requestSubmit();
        } else {
          resolve();
        }
      });
    };

    try {
      this.completedForms.clear();
      if (this.hasMediaFormTarget) {
        await submitForm(this.mediaFormTarget);
      }
      if (this.hasAvailabilitiesFormTarget) {
        await submitForm(this.availabilitiesFormTarget);
      }
      if (this.hasYesNoQuestionsFormTarget) {
        await submitForm(this.yesNoQuestionsFormTarget);
      }
      if (this.hasOpenQuestionsFormTarget) {
        await submitForm(this.openQuestionsFormTarget);
      }

    } catch (error) {
      console.error('Failed to submit one of the secondary forms', error);
    }
  }
}
