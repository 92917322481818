import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submissions"
export default class extends Controller {
  static targets = ['checkbox', 'agencyInfo', 'yesNoJustification']

  connect() {
    if (this.hasAgencyInfoTarget){
      this.toggleAgencyInfo()
    }
    if (this.hasYesNoJustificationTarget){
      this.toggleYesNoJustification()
    }
  }

  toggleAgencyInfo() {
    if (this.checkboxTarget.checked) {
      this.agencyInfoTarget.classList.remove('hidden')
    } else {
      this.agencyInfoTarget.classList.add('hidden')
    }
  }

  toggleYesNoJustification() {
    if (this.checkboxTarget.checked) {
      this.yesNoJustificationTarget.classList.remove('hidden')
    } else {
      this.yesNoJustificationTarget.classList.add('hidden')
    }
  }
}
