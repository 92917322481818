import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input"
export default class extends Controller {
  static targets = ["field", "icon"]

  toggleVisibility() {
    if (this.fieldTarget.type === "password") {
      this.fieldTarget.type = "text"
      this.iconTarget.classList.remove("bi-eye")
      this.iconTarget.classList.add("bi-eye-slash")
    } else {
      this.fieldTarget.type = "password"
      this.iconTarget.classList.remove("bi-eye-slash")
      this.iconTarget.classList.add("bi-eye")
    }
  }
}
