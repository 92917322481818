import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["downloadTypeModalProfilesIds"]
  static values = { albumId: Number }

  download(event) {
    event.preventDefault();
    this.setProfileIds();
    this.showLoader();

    const profileIds = this.downloadTypeModalProfilesIdsTarget.value;
    const downloadType = document.querySelector('select[name="download_type"]').value;
    const albumId = this.albumIdValue;

    fetch(`/manager/albums/${albumId}/download`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        profiles_ids: profileIds,
        download_type: downloadType
      })
    })
        .then(response => {
          if (downloadType === 'all_files') {
            // Para 'all_files', manejamos el mensaje de éxito/error con Swal
            return response.json().then(data => {
              this.hideLoader();
              this.closeModal();

              Swal.fire({
                title: data.status === 'success' ? 'Success!' : 'Error!',
                text: data.message,
                icon: data.status,
                timer: 5000,
                showConfirmButton: false
              });
            });
          } else if (response.ok) {
            // Para 'pdf' o 'excel', descarga el archivo
            return response.blob();
          } else {
            throw new Error("Error downloading file");
          }
        })
        .then(blob => {
          if (downloadType !== 'all_files') {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;

            switch (downloadType) {
              case 'pdf':
                a.download = `album_${albumId}.pdf`;
                break;
              case 'excel':
                a.download = `album_${albumId}.xlsx`;
                break;
              default:
                throw new Error("Invalid download type");
            }

            document.body.appendChild(a);
            a.click();
            a.remove();

            this.hideLoader();
            this.closeModal();
          }
        })
        .catch(error => {
          console.error('Download error:', error);
          this.hideLoader();
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred while processing the request.',
            icon: 'error',
            showConfirmButton: true
          });
        });
  }

  showLoader() {
    const loader = document.getElementById('loader-overlay');
    if (loader) {
      loader.classList.remove('hidden');
    }
  }

  hideLoader() {
    const loader = document.getElementById('loader-overlay');
    if (loader) {
      loader.classList.add('hidden');
    }
  }

  closeModal() {
    document.getElementById('modalOverlay').classList.add("hidden");
    document.getElementById('modalContainer').classList.add("hidden");
    document.getElementById('modalContainer').innerHTML = "";
  }

  setProfileIds() {
    const profileCheckboxes = document.querySelectorAll('.cv-checkbox-input');
    const selectedCheckboxes = Array.from(profileCheckboxes).filter(checkbox => checkbox.checked);

    if (selectedCheckboxes.length === 0) {
      this.downloadTypeModalProfilesIdsTarget.value = Array.from(profileCheckboxes).map(checkbox => checkbox.value).join(',');
    } else {
      this.downloadTypeModalProfilesIdsTarget.value = selectedCheckboxes.map(checkbox => checkbox.value).join(',');
    }
  }
}
