import moment from "moment";

moment.updateLocale('en', {
    week: {
        dow: 1,
        doy: 4
    }
});

window.moment = moment;
