import { I18n } from "i18n-js";
window.I18n = I18n;

import translationsEn from "./locales/en.json";
import translationsEs from "./locales/es.json";
import translationsCa from "./locales/ca.json";
import translationsPt from "./locales/pt.json";
import translationsFr from "./locales/fr.json";

I18n.t = {
    ...translationsEn,
    ...translationsEs,
    ...translationsCa,
    ...translationsPt,
    ...translationsFr
};