import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="yes-no-questions-fields"
export default class extends Controller {
  static targets = ["checkbox", "ifAffirmativeAsk"]

  connect() {
    this.toggleIfAffirmativeAskInput();
  }

  toggleIfAffirmativeAskInput() {
    if (this.checkboxTarget.checked) {
      this.ifAffirmativeAskTarget.disabled = false;
      this.ifAffirmativeAskTarget.classList.remove('hidden')
    } else {
      this.ifAffirmativeAskTarget.disabled = true;
      this.ifAffirmativeAskTarget.classList.add('hidden')
    }
  }
}
