{
  "pt": {
    "js": {
      "checkout": {
        "selected_plan": "Plano selecionado",
        "selected_period": "Período selecionado",
        "price": "Preço",
        "monthly": "Mensal",
        "yearly": "Anual",
        "per_month": "/mês",
        "per_year": "/ano"
      },
      "copy_link_modal": {
        "confirm_button_aria_label": "Copiar para a área de transferência",
        "confirm_button_text": "<i class=\"fa fa-clipboard\"></i> Copiar",
        "connected": "Conectado ao modal de copiar link",
        "copied_message": "O link foi copiado para sua área de transferência.",
        "copied_title": "Copiado!",
        "error_message": "Não foi possível copiar o texto: ",
        "focus_confirm": "Focar confirmar",
        "html": "<b>%{link}</b>",
        "show_close_button": "Mostrar botão fechar",
        "title": "<strong>Copiar link</strong>"
      },
      "image_upload": {
        "error_invalid_file_type": "Tipo de arquivo inválido",
        "error_file_must_be_jpg_or_png": "O arquivo deve ser uma imagem JPG ou PNG.",
        "error_file_too_large": "Arquivo muito grande",
        "error_max_size_10mb": "O arquivo deve ser menor que 10MB."
      }
    }
  }
}
