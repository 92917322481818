import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
    connect() {
        const input = this.element
        const options = {
            delimiters: ",",
            placeholder: input.dataset.placeholder || 'Add tags',
            editTags: false,
            dropdown: {
                enabled: 0,
            },
            // Añade esta línea para configurar el formato del valor del input
            originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(', ')
        }

        if (input.dataset.suggestions) {
            options.whitelist = JSON.parse(input.dataset.suggestions)
            options.dropdown.enabled = 1
            options.dropdown.maxItems = 10
            options.dropdown.classname = 'tags-suggestions-list'
            options.enforceWhitelist = false
        }

        this.tagify = new Tagify(input, options)
    }

    disconnect() {
        if (this.tagify) {
            this.tagify.destroy()
        }
    }
}
