// app/javascript/controllers/upload_document_component_controller.js

import { Controller } from "@hotwired/stimulus"

// Conecta con data-controller="upload-document-component"
export default class extends Controller {
  static targets = ['fileInput', 'fileName', 'paperclipIcon', 'removeIcon', 'removeDocumentField'];

  connect() {
    this.updateFileNameDisplay();
  }

  toggleFileBrowser(event) {
    event.preventDefault();

    if (this.fileInputTarget.files.length > 0 || this.hasInitialFile()) {
      this.fileInputTarget.value = '';
      this.element.dataset.fileName = '';
      this.updateFileNameDisplay();
    } else {
      this.fileInputTarget.click();
    }
  }

  fileSelected(event) {
    this.element.dataset.fileName = '';
    this.updateFileNameDisplay();
  }

  updateFileNameDisplay() {
    const fileNameElement = this.fileNameTarget;
    let fileName = '';

    if (this.fileInputTarget.files.length > 0) {
      const file = this.fileInputTarget.files[0];
      fileName = file.name;
    } else if (this.element.dataset.fileName) {
      fileName = this.element.dataset.fileName;
    }

    if (fileName) {
      if (fileName.length > 50) {
        fileName = fileName.substring(0, 47) + '...';
      }
      fileNameElement.textContent = fileName;
      this.paperclipIconTarget.classList.add('hidden');
      this.removeIconTarget.classList.remove('hidden');
      this.removeDocumentFieldTarget.value = '0';
    } else {
      fileNameElement.textContent = 'Adjuntar documento';
      this.paperclipIconTarget.classList.remove('hidden');
      this.removeIconTarget.classList.add('hidden');
      this.removeDocumentFieldTarget.value = '1';
    }
  }

  hasInitialFile() {
    return this.element.dataset.fileName && this.element.dataset.fileName.length > 0;
  }
}
