import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "toDate"]

  connect() {
    this.toggleDateInput();
  }

  toggleDateInput() {
    const flatpickrInstance = this.toDateTarget._flatpickr;

    if (flatpickrInstance) {
      const altInput = flatpickrInstance.altInput;

      if (this.checkboxTarget.checked) {
        altInput.disabled = true;
      } else {
        altInput.disabled = false;
      }
    }
  }
}
