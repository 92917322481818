import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-preview"
export default class extends Controller {
  static targets = ["source", "preview"]

  updatePreview() {
    const input = this.sourceTarget;
    const file = input.files[0];

    if (!this.isValidFileType(file)) {
      this.showError('error_invalid_file_type', 'error_file_must_be_jpg_or_png');
      input.value = "";
      return;
    }

    if (!this.isValidFileSize(file)) {
      this.showError('error_file_too_large', 'error_max_size_10mb');
      input.value = "";
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => this.previewTarget.src = e.target.result;
      reader.readAsDataURL(file);
    }
  }

  triggerUpload() {
    this.sourceTarget.click();
  }

  isValidFileType(file) {
    const acceptedTypes = ["image/jpeg", "image/png"];
    return acceptedTypes.includes(file.type);
  }

  isValidFileSize(file) {
    const maxSizeInMB = 10;
    return file.size / 1024 / 1024 < maxSizeInMB;
  }

  showError(titleKey, textKey) {
    const locale = document.documentElement.lang || 'en';
    Swal.fire({
      title: I18n.t[locale]['js']['image_upload'][titleKey],
      text: I18n.t[locale]['js']['image_upload'][textKey],
      icon: 'error',
      timer: 1500,
      showConfirmButton: false,
    });
  }
}
