import { Controller } from "@hotwired/stimulus"


// Conecta con data-controller="box-with-link"
export default class extends Controller {
  static targets = ["dropdown"]

  connect() {
    document.addEventListener('box-with-link:closeAll', this.closeDropdown.bind(this))

    document.addEventListener('click', this.handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener('box-with-link:closeAll', this.closeDropdown.bind(this))
    document.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  openDropdown(event) {
    event.stopPropagation()

    const closeAllEvent = new CustomEvent('box-with-link:closeAll', { detail: { source: this.element } })
    document.dispatchEvent(closeAllEvent)

    this.dropdownTarget.classList.toggle("hidden")
  }

  closeDropdown(event) {
    if (event.detail && event.detail.source !== this.element) {
      this.dropdownTarget.classList.add("hidden")
    }
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add("hidden")
    }
  }

  copy_link(event) {
    event.preventDefault()
    const clipboardText = event.currentTarget.getAttribute('data-clipboard-text')
    const locale = document.body.getAttribute('data-locale') || 'en'

    navigator.clipboard.writeText(clipboardText).then(() => {
      Swal.fire({
        title: I18n.t[locale]['js']['copy_link_modal']['copied_title'],
        text: I18n.t[locale]['js']['copy_link_modal']['copied_message'],
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      })
    }).catch(err => {
      Swal.fire({
        title: I18n.t[locale]['js']['copy_link_modal']['error_title'],
        text: I18n.t[locale]['js']['copy_link_modal']['error_message'],
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      })
    })
  }
}
