import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-range-picker"
export default class extends Controller {
  connect() {
    const element = this.element;
    const ranges = {
      [element.dataset.today]: [moment(), moment()],
      // [element.dataset.yesterday]: [moment().subtract({ days: 1 }), moment().subtract({ days: 1 })],
      // [element.dataset.lastSevenDays]: [moment().subtract({ days: 6 }), moment()],
      // [element.dataset.lastThirtyDays]: [moment().subtract({ days: 29 }), moment()],
      [element.dataset.thisWeek]: [moment().startOf('week'), moment().endOf('week')],
      [element.dataset.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
      // [element.dataset.lastMonth]: [moment().subtract({ months: 1 }).startOf('month'), moment().subtract({ months: 1 }).endOf('month')],
      // [element.dataset.lastThreeSixtyFiveDays]: [moment().subtract({ days: 364 }), moment()],
    };

    const userLocaleFormat = element.dataset.dateFormat || 'YYYY-MM-DD';

    if (element.value) {
      const dates = element.value.split(' - ');
      if (dates.length === 2) {
        const start = moment(dates[0], 'YYYY-MM-DD');
        const end = moment(dates[1], 'YYYY-MM-DD');
        element.value = start.format(userLocaleFormat) + ' - ' + end.format(userLocaleFormat);
      }
    }

    new DateRangePicker(element, {
      alwaysShowCalendars: true,
      autoApply: true,
      showWeekNumbers: true,
      opens: 'center',
      ranges: ranges,
      locale: {
        format: userLocaleFormat,
      }
    }, this.submit.bind(this));
  }

  submit(start, end) {
    const userLocaleFormat = this.element.dataset.dateFormat || 'YYYY-MM-DD';

    this.element.value = start.format(userLocaleFormat) + ' - ' + end.format(userLocaleFormat);

    const startDateInput = document.querySelector(`input[name="${this.element.name.replace('[date_between]', '[start_date]')}"]`);
    const endDateInput = document.querySelector(`input[name="${this.element.name.replace('[date_between]', '[end_date]')}"]`);

    if (startDateInput && endDateInput) {
      startDateInput.value = start.format('YYYY-MM-DD');
      endDateInput.value = end.format('YYYY-MM-DD');
    }

    this.element.dispatchEvent(new Event('change'));
  }
}